.transition (@duration) {
    transition: all ease @duration;
}

.line-height(@line-height, @size) {
    line-height: @line-height / @size;
}

.font-size(@font-size) {
    @rem: round(@font-size / @base-font-size, 5);
    font-size: ~"@{rem}rem";
}

.letter-spacing(@letter-spacing) {
    @rem: round(@letter-spacing / @base-font-size, 5);
    letter-spacing: ~"@{rem}rem";
}

.font(@font-size, @line-height: normal, @letter-spacing: normal) {
    @size: round(@font-size / @base-font-size, 5);
    @spacing: round(@letter-spacing / @base-font-size, 5);

    font-size: ~"@{size}rem";
    line-height: @line-height / @font-size;
    letter-spacing: ~"@{spacing}rem";
}

.hidden {
    display: none !important;
}

.full-width {
    width: 100%;
}