.link--cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    text-indent: -999999px;
}

.text-align--left {
    text-align: left;
}

.margin {
    &--none {
        margin: 0;
    }

    &--bottom {
        margin-bottom: 1.875rem;

        &--none {
            margin-bottom: 0;
        }

        &--small {
            margin-bottom: 0.625rem;
        }

        &--large {
            margin-bottom: 3.125rem;
        }
    }

    &--top {
        margin-top: 1.875rem;

        &--list {
            margin-top: 2.5rem;
        }

        &--auto {
            margin-top: auto;
        }
    }
}

.padding {
    &--none {
        padding: 0;
    }
}

.img {
    &--radius {
        border-radius: 50%;
    }

    &--cover {
        object-fit: cover;
    }

    &--fill {
        width: 100%;
        height: auto;
    }
}

.no-scroll {
    overflow: hidden;
}

.spacer {
    display: block;
    margin: 80px 0;
}

.word-break {
    word-break: break-word;
}

.no-list-style {
    list-style-type: none;
}

.position--cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.position {
    &--relative {
        position: relative;
    }
}

.label--hide {
    position: absolute;
    left: -9999999px;
}

.tablet-hide {
    @media @tablet {
        display: none;
    }
}

.mobile-hide {
    @media @mobile {
        display: none !important;
    }
}

.desktop-hide {
    display: none !important;

    @media @mobile {
        display: block !important;
    }
}

.flex {
    display: flex;

    &--column {
        flex-direction: column;
    }

    &--tablet-column {
        @media @tablet {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &--row {
        flex-direction: row;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &--gap {
        gap: @layout-padding;

        @media @tablet {
            gap: 1.25rem;
        }

        &--small {
            gap: 0.625rem;
        }
    }

    &--start {
        align-items: flex-start;
    }

    &--end {
        align-items: flex-end;
    }

    &--center {
        align-items: center;
    }

    &--justify {
        justify-content: space-between;
    }
}

.backgroundPurple {
    background-color: var(--brand-primary--tint);
    color: var(--brand-primary--shade);
}