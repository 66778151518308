.cta-panel {
    border: 1px solid @c-yellow;
    background-color: @c-yellow--light;
    padding: 2rem;
    display: flex;
    align-items: center;
    color: #212b32;
    // margin: 30px 0;
    // &__icon {
    //     font-size: 4.375rem;
    //     margin-right: 1.875rem;
    // }

    &__title {
        font-size: 1.5rem;
    }

    a {
        color: inherit;
    }
}