﻿.doc-list {
    // &__filter {
    //     background-color: @c-grey--light;
    //     padding: 1.875rem;
    //     margin-bottom: 1.875rem;
    //     &-label {
    //         font-weight: 700;
    //         font-size: .9rem;
    //         margin-bottom: 0.625rem;
    //         display: block;
    //         &:before {
    //             content: '\f002';
    //             font-family: @font-awesome;
    //             font-weight: 900;
    //             margin-right: 0.3125rem;
    //         }
    //     }
    // }

    &__section {
        .doc-list__title {
            margin: 1.875rem 0 1.875rem 1.875rem;
        }

        .doc-list__section {
            margin: 0 0 0 1.875rem;
        }
    }

    &__title {
        //     &:not(:first-of-type) {
        // margin-top: 3.75rem;
        //}
        // a[data-toggle="collapse"] {
        //     display: none;
        // }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__link {
        padding: 0.625rem 6.25rem 0.625rem 1.25rem;
        background-color: #FFF;
        border: 1px solid @c-grey;
        display: flex;
        align-items: center;
        text-decoration: none;
        position: relative;
        .transition(.3s);

        &:hover {
            background-color: @c-grey--light;
        }
        // &:before {
        //     content: "\f15b";
        //     font-family: @font-awesome;
        //     font-weight: 900;
        //     margin-right: 0.625rem;
        // }

        &:after {
            background-color: @c-main;
            font-size: .6rem;
            text-transform: uppercase;
            color: #FFF;
            padding: 7px 0.625rem;
            border-radius: 0.3125rem;
            position: absolute;
            right: 1.25rem;
            line-height: 1;
        }

        &.pdf {
            // &:before {
            //     content: "\f1c1";
            // }
            &:after {
                content: 'pdf';
            }
        }

        &.doc, &.docx, &.txt {
            // &:before {
            //     content: "\f15b";
            // }
            &:after {
                content: 'doc';
            }
        }

        &.dotx {
            // &:before {
            //     content: "\f15b";
            // }
            &:after {
                content: 'dotx';
            }
        }

        &.potx {
            // &:before {
            //     content: "\f1c4";
            // }
            &:after {
                content: 'potx';
            }
        }

        &.ppt, &.pptx {
            // &:before {
            //     content: "\f1c4";
            // }
            &:after {
                content: 'ppt';
            }
        }

        &.rtf {
            // &:before {
            //     content: "\f0f6";
            // }
            &:after {
                content: 'rtf';
            }
        }

        &.docx {
            // &:before {
            //     content: "\f15b";
            // }
            &:after {
                content: 'docx';
            }
        }

        &.csv {
            // &:before {
            //     content: "\f0ce";
            // }
            &:after {
                content: 'csv';
            }
        }

        &.jpg, &.jpeg, &.png, &.gif {
            // &:before {
            //     content: "\f1c5";
            // }
            &:after {
                content: 'image';
            }
        }

        &.xlsx {
            // &:before {
            //     content: "\f0ce";
            // }
            &:after {
                content: 'xlsx';
            }
        }
    }
}