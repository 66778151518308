﻿#nearestServicesMap {
    height: 100%;

    @media @tablet{
        min-height: 300px;
    }
}
.find-nearest__location{
    cursor: pointer;

    font-size: 19px;
}
.find-nearest__map-link{
    float: right;
}
.postcode-label{
    display: inline-block;
}
.form-group--location-search {
    display: inline-block;
}
.back-divider {
    margin: 0 8px;
}