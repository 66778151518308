body.ie--11 {
    .card {
        display: block;
    }

    .flex--gap {
        > * {
            margin: 0 @layout-padding @layout-padding 0;
        }
    }

    .flex--gap--small {
        > * {
            margin: 0 0.625rem 0.625rem 0;
        }
    }
}   