.card-grid__title {
    color: var(--brand-primary--shade);
}

.card--white {
    background-color: #fff;
}

.card__title,
.card__link {
    color: var(--brand-primary--shade);
}

.card__summary--small {
    font-size: 1.125em;
}

.card__summary--primary {
    color: var(--brand-primary--shade);
}

.card__link--large {
    font-size: var(--font-size-plus-4);
}

.max-height {
    max-height: 100px;
}

.card__title--regular {
    font-weight: var(--regular-weight);

    p {
        line-height: 1.2;
    }
}

.card__content--key-facts {
    padding: ~"calc(var(--baseline)) calc(var(--baseline)) calc(var(--baseline)* 2) calc(var(--baseline))";

    @media @tablet-min {
        padding: ~"calc(var(--baseline)* 1.5) calc(var(--baseline)* 1.5) calc(var(--baseline)* 2)";
    }
}

.card__svg {
    background-color: var(--brand-secondary);
    border-radius: 50%;
    padding: 1.25rem;
    width: 6.25em;
    height: 6.25em;
    margin-bottom: var(--baseline);
}

.mini-icon {
    background-color: var(--light-grey-1);
    padding: .5rem;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border-radius: 50%;
}



.card--image {
    position: relative;
}