html {
    font-size: @base-font-size*1px;
    scroll-behavior: smooth;
}

body {
    // color: @c-body;
    padding: 0;
    margin: 0;
    // font-family: @font-sans-serif;
    font-size: inherit;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex: 1;
}

main {
    flex: 1 0 auto;
    height: 100%;
    // removed this to make hero images work with the breadcrumb.
    // overflow: hidden;
}

.icon {
    font-size: 1.25em;
}

strong,
b {
    font-weight: var(--bold-weight);
}


.editor {

    p,
    a,
    strong,
    b {
        color: var(--brand-primary--shade);
    }

    p {
        margin-bottom: calc(var(--baseline) / 1.5);
    }
}



.backgroundPink {
    background-color: var(--brand-secondary);
    color: white;

    .content-block__title,
    .editor p,
    .editor a {
        color: #fff;
    }
}