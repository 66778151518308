﻿.carousel {
    &__slide {
        position: relative;
    }

    &__title {
        margin-bottom: 0;

        p {
            font-size: inherit;
            font-weight: inherit;
        }
    }

    &__content {
        z-index: 10;
        margin: 10px 0 30px;

        @media @tablet-min {
            position: absolute;
            top: 50%;
            left: 6.25rem;
            transform: translateY(-50%);
            background-color: rgba(0, 0, 0, .7);
            padding: 1.875rem;
            color: #FFF;
            max-width: 33.75rem;
            width: 100%;
        }

        &:empty {
            padding: 0;
            background: none;
        }

        a {
            color: inherit;
        }
    }

    &__img {
        height: auto;
    }

    &__button {
        margin-top: 1.25rem;
    }
}

.splide {
    margin-bottom: 5rem;

    &__arrows {
        @media @tablet {
            position: relative;
            aspect-ratio: ~"1200/600";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }

    &__arrow {
        appearance: none;
        background: none;
        border: 0;
        cursor: pointer;
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);

        &--prev {
            left: 1.25rem;
            transform: scaleX(-1);
            transform: translateY(-50%) scaleX(-1);
        }

        &--next {
            right: 1.25rem;
        }

        path {
            fill: #FFF;
        }
    }

    &__pagination {
        position: absolute;
        padding: 0;
        margin: 0 0 0 -0.3125rem !important;

        &__page {
            appearance: none;
            width: 1.875rem;
            height: 0.625rem;
            background-color: #c2d2d6;
            border: 0;
            margin: 0 5px;
            cursor: pointer;
            .transition(.3s);

            &.is-active {
                background-color: @c-main;
            }
        }
    }
}