.tabs {
    &__scroll {
        max-width: 100%;
        overflow-x: auto;
    }

    &__container {
        display: flex;
        border-bottom: 5px solid @c-blue;
    }

    &__tab {
        font-size: 1.2rem;
        position: relative;
        background-color: darken(#f0f4f5, 10%);
        cursor: pointer;
        margin-right: 5px;
        padding: 15px 20px;
        display: block;
        line-height: 1;
        font-weight: 700;
        flex-shrink: 0;
        .transition(.3s);

        &:not(&--active) {
            &:hover {
                background-color: darken(#f0f4f5, 15%);
            }
        }

        &--active {
            background-color: @c-blue;
            color: #FFF;
        }
    }

    &__content {
        display: none;
        background-color: #fff;

        &--active {
            display: block;
            padding: 1.875rem;
        }
    }

    &__title {
        font-weight: 700;
        margin-bottom: 1.25rem;
    }
}