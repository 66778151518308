.search-results {
    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__item {
        border-bottom: 2px solid #eee;
        padding: 20px 0;
        position: relative;
        display: flex;
        justify-content: space-between;

        &:before {
            content: '';
            position: absolute;
            top: -2px;
            right: -20px;
            bottom: -2px;
            left: -20px;
            background-color: #eee;
            opacity: 0;
            z-index: -1;
            .transition(.3s);
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }

    &__title {
        margin-bottom: 10px;
    }

    &__score {
        font-weight: 500;
    }
}