.feature-panel {
    // margin-bottom: 1.875rem;
    // background-color: #EDEDED;
    padding: 0 ;

    &__row {
        margin: 0;
    }

    &__col {
        padding: 0;
        margin: 0 ;
    }

    &__body {
        padding: 3.4375rem 1.875rem 1.875rem;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__subtitle {
        .font(@font-size: 14, @line-height: 19, @letter-spacing: 2.1);
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 20px;
        display: block;
    }

    &__title {
        .font(@font-size: 34, @line-height: 40, @letter-spacing: -0.34);
        margin-bottom: 30px;
        font-weight: 700;

        @media @mobile {
            .font-size(25);
            margin-bottom: 20px;
        }
    }
}