.testimonial {
    text-align: center;

    &__text {
        .font(@font-size: 26, @line-height: 36, @letter-spacing: -0.26);
        max-width: 800px;
        margin: 2rem auto 3.5rem;
        font-weight: 400;

        @media @mobile {
            .font-size(18);
        }

        p {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &__sub {
        .font(@font-size: 15, @line-height: 24, @letter-spacing: 0.38);
        text-transform: uppercase;
    }

    strong {
        color: @c-main;
    }
}