.billboard__content {
    @media @tablet-min {
        gap: calc(var(--baseline)* 1.5);
    }
}

.billboard__category,
.billboard__title {
    @media @tablet-min {
        margin-bottom: 0;
    }
}

.billboard__item .row {
    align-items: center;
}

.billboard__category {
    font-size: var(--font-size-plus-3);
}

.billboard__title {
    font-weight: var(--regular-weight);

    .case-studies & {
        font-weight: 600;
        color: var(--brand-primary--shade);
    }
}

.billboard__cta {
    color: var(--brand-primary);
    font-size: var(--font-size-plus-3);

    .icon {
        color: var(--brand-secondary);
    }
}

.billboard {
    &.backgroundPurple {
        .billboard__cta {
            color: var(--brand-primary--shade);

            .icon {
                color: var(--brand-primary--shade);
            }
        }
    }
}

.billboard__cta--homepage {
    align-items: center;
    display: flex;
    gap: .75rem;

    &:hover {
        gap: 1rem;
    }

    // .bg-light & {
    //     color: var(--brand-primary);
    // }
}