.search-results {
    background-color: var(--light-grey-1);
}

.search {
    margin: @content-padding 0;

    &__row {
        align-items: flex-start;
    }

    &__col {
        display: block;

        &--sticky {
            @media @tablet-min {
                position: sticky;
                top: 120px;
                // overflow-y: auto;
                // max-height: 85vh;
                margin-top: 50px;

                &::-webkit-scrollbar {
                    width: 8px
                }

                &::-webkit-scrollbar-track {
                    background: #ffffff;
                    border-radius: 8px
                }

                &::-webkit-scrollbar-thumb {
                    background: #bbbbbb;
                    border-radius: 8px
                }
            }
        }
    }

    &__sidebar {
        background-color: #fff;
        padding: var(--baseline);
    }

    &__search-box {
        width: 100%;
    }

    &__label {
        font-weight: 600;
        margin-bottom: 0.625rem;
        display: block;
        font-size: var(--font-size-plus-1);
        color: var(--brand-primary--shade);

        &:not(:first-of-type) {
            margin-top: 1.25rem;
        }
    }

    &__input-container {
        position: relative;
    }

    &__input {
        border: 0;
        padding: 15px;
        width: 100%;
    }

    &__input-clear {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;

        &--show {
            display: block;
        }
    }

    &__button {
        flex: 1;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        @media @mobile {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__count {
        font-weight: 600;
        color: var(--brand-primary--shade);
        display: block;

        @media @mobile {
            margin-bottom: 10px;
        }
    }

    &__filter-by {
        display: flex;
        align-items: center;
    }

    &__filter-label {
        font-weight: 600;
        margin-right: 15px;
        font-size: .9rem;
        color: var(--brand-primary--shade);
    }

    &__filter-dropdown {
        padding: 6px 15px;
        border: 2px solid #eee;
        appearance: none;
        padding-right: 40px;
        background-color: #FFF;
        color: var(--brand-primary--shade);

        &-container {
            position: relative;

            &:after {
                content: '';
                background-image: url(/img/icons/chevron.svg);
                background-size: 100% 100%;
                width: 1rem;
                height: 1rem;
                transform: rotate(180deg);
                position: absolute;
                top: 12px;
                right: 15px;
                pointer-events: none;
            }
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-flow: column wrap;
        gap: var(--baseline);
    }

    &__item {
        padding: var(--baseline);
        position: relative;
        margin: 0;
        background-color: #fff;

        &--padding {
            @media @mobile-min {
                padding-right: 50px;
            }
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }

    &__tag {
        background-color: var(--brand-primary);
        color: #FFF;
        font-size: .7rem;
        text-transform: uppercase;
        font-weight: 700;
        padding: 0.5rem 0.625rem;
        border-radius: 0.3125rem;
        display: inline-block;
        margin-bottom: 0.625rem;

        @media @mobile-min {
            position: absolute;
            right: 0;
        }
    }

    &__title {
        margin-bottom: 0.625rem;
        font-weight: 600;
        font-size: var(--font-size-plus-3);
        color: var(--brand-primary--shade);

        @media @mobile-min {
            padding-right: 60px;
        }
    }

    &__sub-title {
        color: var(--brand-primary);
        font-size: 1rem;
        font-weight: 600;
    }

    &__content {
        margin-top: 0.625rem;
        font-size: 1rem;
        color: var(--brand-primary--shade);

        &:last-of-type {
            margin-bottom: 0;
            font-size: var(--font-size-plus-1);
        }
    }

    &__filter-trigger {
        background-color: var(--brand-primary);
        padding: 15px 20px;
        border: 0;
        color: #FFF;
        margin: 20px 0;
        width: 100%;
        display: block;
        font-size: 1.1rem;
        //  font-family: @font-sans-serif;
        position: relative;
        text-align: left;
        // border-radius: 0.25rem;
        box-shadow: 0 4px 0 darken(@c-main, 15%);

        &:after {
            content: '';
            background-image: url(/img/icons/menu.svg);
            background-size: 100% 100%;
            width: 1.8rem;
            height: 1.8rem;
            margin-left: 0.625rem;
            position: absolute;
            right: 0.9375rem;
            margin-top: -0.1875rem;
        }

        @media @tablet-min {
            display: none;
        }
    }

    &__filter-background {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .7);
        z-index: 200;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        .transition(.3s);

        &--show {
            opacity: 1;
            pointer-events: all;
            visibility: visible;
        }
    }

    &__filters {
        @media @tablet {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #FFF;
            padding: 0 20px 0 20px;
            z-index: 1000;
            overflow-y: auto;
            transform: translateX(-100%);
            display: flex;
            flex-direction: column;
            max-width: 300px;
            .transition(.3s);

            &--show {
                transform: translateX(0);
                box-shadow: 0 0 50px 0 rgba(0, 0, 0, .3);
            }
        }
    }

    &__filters-title {
        position: sticky;
        top: 0;
        right: 0;
        left: 0;
        width: ~"calc(100% + 40px)";
        margin: 0 -20px 30px;
        border: 0;
        background-color: var(--brand-primary);
        color: #FFF;
        padding: 15px;
        font-size: 1.1rem;
        font-weight: 500;
        z-index: 201;
        // font-family: @font-sans-serif;

        &:after {
            content: '';
            background-image: url(/img/icons/close.svg);
            background-size: 100% 100%;
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            right: 0.9375rem;
            filter: invert(1);
        }

        @media @tablet-min {
            display: none;
        }
    }

    &__filters-apply {
        position: sticky;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 -20px;
        background-color: @c-tertiary;
        color: #FFF;
        padding: 1.25rem;
        border: 0;
        display: block;
        margin-top: auto;
        font-size: 1.2rem;
        // font-weight: 600;

        @media @tablet-min {
            display: none;
        }
    }

    &__aggregation {
        margin: 30px 0;

        @media @tablet {
            margin: 0 0 30px 0;
        }

        &--collapse {
            .search__aggregation-title {
                &:before {
                    transform: scaleY(-1);
                }
            }

            .search__aggregation-scroll {
                max-height: 0;
                overflow: hidden;
            }
        }
    }



    &__aggregation-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    &__aggregation-title {
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--brand-primary--shade);
        border-bottom: 2px solid #eee;
        margin-bottom: 0;
        padding-bottom: 15px;
        cursor: pointer;
        position: relative;
        padding-right: 30px;
        user-select: none;
        background: transparent;
        width: 100%;
        text-align: left;

        &:before {
            content: '';
            background-image: url(/img/icons/chevron.svg);
            background-size: 100% 100%;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 0.1875rem;
            right: 0;
            .transition(.3s);

            // @media @mobile {
            //     transform: scaleY(-1);
            // }
        }
    }


    &__aggregation-item {
        padding: 10px 0;
        margin: 0;
        border-bottom: 1px solid #eee;

        &:last-of-type {
            border-bottom: 0;
        }
    }

    &__aggregation-label {
        cursor: pointer;
        margin: 0;

        input[type=checkbox] {
            display: block;

            &:checked+&__aggregation-icon {
                &:before {
                    content: '\f14a';
                }
            }
        }

        input[type=radio] {
            display: none;

            // ~&__aggregation-icon {
            //     &:before {
            //         content: '\f1db';
            //     }
            // }

            // &:checked+&__aggregation-icon {
            //     &:before {
            //         content: '\f111';
            //     }
            // }
        }
    }

    &__aggregation-icon {
        padding-left: 25px;
        position: relative;
        font-size: 1rem;

        &:before {
            content: '';
            background-image: url(/img/icons/checkbox.svg);
            background-size: 100% 100%;
            width: 1rem;
            height: 1rem;
            position: absolute;
            left: 0;
            top: 0.0625rem;
        }

        &--active {
            &:before {
                background-image: url(/img/icons/checkbox--checked.svg);
            }
        }
    }

    &__aggregation-scroll {
        .transition(.5s);
    }

    &__aggregation-count {
        color: var(--brand-primary);
        font-weight: 600;
        font-size: .8rem;
        white-space: nowrap;
    }

}

.search-results__autocomplete {

    .autocomplete__hint,
    .autocomplete__input,
    .autocomplete__hint:focus,
    .autocomplete__input:focus {
        background-color: var(--light-grey-1);
        color: var(--off-black);
    }

    .autocomplete__input:focus {
        color: var(--brand-primary--shade);
        font-weight: 600;
    }

    .hero-form__icon {
        fill: var(--brand-primary--shade);
    }
}

.search__no-results {
    font-size: var(--font-size-plus-3);
    color: var(--brand-primary--shade);
    margin-top: 50px;
    font-weight: 600;
}

.search__filter-header {
    padding-bottom: var(--baseline);
    font-size: var(--font-size-plus-1);
    border-bottom: 2px solid #eee;
    color: var(--brand-primary--shade);
    font-weight: 600;
}

.button--clearFilters {
    background-color: transparent;
    color: var(--brand-primary);
    text-decoration: underline;

    &:hover {
        background-color: var(--light-grey-1);
    }
}

.activeFilterList {
    display: flex;
    column-gap: .75rem;
    grid-row-gap: .325rem;
    flex-wrap: wrap;
    padding-top: calc(var(--baseline) / 2);
    padding-bottom: var(--baseline);
}