@tablet: ~"only screen and (max-width : 61.865em)";
@tablet-min: ~"only screen and (min-width : 61.866em)";
@mobile: ~"only screen and (max-width : 480px)";
@mobile-min: ~"only screen and (min-width : 481px)";
@mobile-s: ~"only screen and (max-width : 320px)";

// colours
@c-body: #212b32;
@c-main: @c-blue;
@c-secondary: @c-yellow;
@c-tertiary: @c-green;
@c-dark: #3C3C3B;
@c-grey: lighten(#e5e5e5, 2%);
@c-grey--light: darken(#f7f7f7, 2%);

@c-blue: #124987;
@c-blue--light: #d4d6e6;
@c-yellow: #f8f400;
@c-yellow--light: #fffaad;
@c-red: #ad0038;
// @c-red: #d5281b;
@c-red--light: #fbd3b9;
@c-green: #00775b;
// @c-green: #007f3b;


// height & width values
@container-width: 1200px;
@content-padding: 4rem;

@layout-padding: 30px;
@column-padding: 0.875rem;

@header-height: 9.25rem;
@header-height--mobile: 6.0625rem;

// fonts
@base-font-size: 16;
@font-sans-serif: 'Montserrat', 'Segoe UI', sans-serif;
@font-awesome: "Font Awesome 5 Free";

// Animation


:root {
    --brand-secondary--shade: hsl(314, 56%, 30%);
    --brand-primary--tint-dark: hsl(226, 44%, 50%);
    --brand-primary--tint: #AAAFD1;
}