.meet-the-team {
    overflow: hidden;
    position: relative;

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    &__panel {
        position: static;
        padding: @column-padding;
        cursor: pointer;
        .transition(.3s);

        &--hide{
            pointer-events: none;
        }

        &:not(&--active) {
            padding-bottom: 0 !important;
        }

        &:hover {
            background-color: #f0f4f5;
        }

        &--active {
            .meet-the-team__description {
                opacity: 1;
                visibility: unset;
                pointer-events: unset;
            }

            .meet-the-team__content {
                &:after {
                    transform: rotate(45deg);
                    animation: team_arrow .5s ease;
                    animation-delay: .3s;
                    animation-fill-mode: forwards;
                }
            }
        }
    }

    &__img {
        width: 100%;
        height: auto;
    }

    &__expand {
        display: flex;
        align-items: center;

        &:after {
            content: '';
            background-image: url(/img/icons/chevron.svg);
            background-size: 100% 100%;
            display: block;
            width: 13px;
            height: 13px;
            transform: rotate(90deg);
            margin-left: 7px;
        }
    }

    &__content {
        position: relative;
        padding-bottom: @column-padding;

        &:after {
            content: '';
            position: absolute;
            background-color: @c-main;
            display: block;
            height: 40px;
            left: 50%;
            margin-left: -20px;
            bottom: -60px;
            transform: rotate(45deg);
            width: 40px;
            opacity: 0;
        }
    }

    &__title {
        position: relative;
        z-index: 3;
        margin-bottom: 0;
        font-weight: 700;
        .font-size(18);
        margin-top: 20px;
    }

    &__subheading {
        margin-bottom: 20px;
        color: @c-blue;
        font-weight: 600;

        &:not(:first-of-type) {
            margin-top: 40px;
        }
    }

    @keyframes team_arrow {
        0% {
            opacity: 1;
            bottom: -125px;
        }

        30% {
            opacity: 1;
        }

        100% {
            bottom: -60px;
            opacity: 1;
        }
    }

    &__sub {
        position: relative;
        z-index: 3;
    }

    &__description-container {
        display: flex;
    }

    &__description {
        position: absolute;
        left: @layout-padding;
        right: @layout-padding;
        background: @c-main;
        opacity: 0;
        .transition(.3s);
        color: #fff;
        padding: 65px 0;
        margin-top: 20px;
        cursor: default;
        pointer-events: none;
        visibility: hidden;

        &:before {
            content: '';
            position: absolute;
            background-color: inherit;
            display: block;
            left: -100vw;
            right: -100vw;
            top: 0;
            bottom: 0;
        }

        p {
            max-width: 850px;
        }
    }

    &__description-text {
        max-width: 904px;
        z-index: 1;

        p {
            .font-size(16);
            .line-height(25, 16);
        }
        a{
            color: #fff;
        }
    }
}



