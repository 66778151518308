/* stylelint-disable color-no-hex */
/* stylelint-enable color-no-hex */
@media (max-width: 40.0525em) {
    .beta-header__search-form {
        padding: 0 16px 17px;
        position: relative;
    }

    .beta-header__search-wrap::after {
        background: #005eb8;
        content: " ";
        height: 57px;
        left: -16px;
        position: absolute;
        width: calc(100% + 32px);
    }

    .beta-search__submit {
        background-color: #f0f4f5;
        border: 0;
        height: 40px;
        z-index: 1;
    }

    .beta-search__submit .beta-icon__search {
        fill: #005eb8;
        height: 27px;
        width: 27px;
    }

    .beta-search__submit:hover {
        background-color: #003d78;
        border: 1px solid #ffffff;
    }

    .beta-search__submit:hover .nhsuk-icon {
        fill: #ffffff;
    }

    .nhsuk-header__menu-toggle {
        right: 0;
    }

    //   .nhsuk-search__input {
    //     border: 1px solid #ffffff;
    //     height: 40px;
    //   }
}

@media (min-width: 48.0625em) {
    .nhsuk-header__navigation {
        max-width: 960px;
    }
}

.nhsuk-header__content--container {
    float: left;
}

@media (max-width: 40.0525em) {
    .nhsuk-header__content--container {
        float: right;
    }
}

.nhsuk-header__login {
    float: left;
    padding-top: 0.5em;
    position: relative;
    z-index: 2;
}

.nhsuk-header__login--link,
.nhsuk-header__login--link:visited {
    color: #ffffff;
    margin-right: 1em;
}

.app-global-alert {
    background-color: @c-yellow;
    padding-bottom: 24px;
    padding-top: 24px;
}

@media print {
    .app-global-alert {
        display: none;
    }
}

.app-global-alert a {
    color: #005eb8;
}

.app-global-alert a:focus {
    background-color: #003d78;
    box-shadow: 0 0 0 4px #003d78;
    color: #fff;
    outline: 4px solid transparent;
    outline-offset: 4px;
}

.app-global-alert a:hover {
    background-color: #005eb8;
    box-shadow: 0 0 0 4px #005eb8;
    color: #fff;
}

.app-global-alert a:active {
    background-color: #002f5c;
    box-shadow: 0 0 0 4px #003d78;
    color: #fff;
}

.app-global-alert__content {
    position: relative;
}

.app-global-alert__content > :first-child {
    margin-top: 0;
}

.app-global-alert__content > :last-child {
    margin-bottom: 0;
}

.app-global-alert__message h2,
.app-global-alert__message h3 {
    font-size: 24px;
    line-height: 1.33333;
    margin: 0;
}

.app-global-alert__message p {
    margin-top: 8px;
    display: block;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0;
}

@media (min-width: 40.0625em) {
    .app-global-alert__message p {
        margin-top: 8px;
    }
}

@media (min-width: 40.0625em) {
    .app-global-alert__message p {
        font-size: 19px;
    }
}

@media (min-width: 48.0625em) {
    .nhsuk-footer__list--three-columns {
        -moz-column-count: 3;
        -moz-column-gap: 24px;
        -webkit-column-count: 3;
        -webkit-column-gap: 24px;
        column-count: 3;
        column-gap: 24px;
    }

    .nhsuk-footer__list--three-columns li {
        float: none;
    }
}

.nhsuk-user-feedback-form {
    line-height: 0;
}

.nhsuk-user-feedback-form__list-item {
    display: inline-block;
}

.app-width-container--full {
    margin: 0;
    max-width: none;
}

.app-main-wrapper--no-padding {
    padding: 0;
}

// .app-homepage {
//   background-color: #fff;
// }

// .app-homepage .nhsuk-section:nth-of-type(odd) {
//   background: #f0f4f5;
// }

// @media (min-width: 40.0625em) {
//   .app-homepage .nhsuk-section:nth-of-type(2) {
//     padding-top: 40px;
//   }
// }
// @media (max-width: 26.3125em) {
//   .app-homepage .nhsuk-section:nth-of-type(2) {
//     padding-top: 40px;
//   }
// }
.app-section__content {
    padding-bottom: 64px;
    padding-top: 64px;
}

.app-section__content > :first-child {
    margin-top: 0;
}

.app-section__content > :last-child {
    margin-bottom: 0;
}

.app-section__content:empty {
    display: none;
}

@media (max-width: 48.0525em) {
    .app-section__content {
        padding-bottom: 48px;
        padding-top: 48px;
    }

    .app-section__content + .app-section__content {
        padding-top: 0;
    }
}

.app-section__content h2 {
    padding-top: 0;
}

.app-section__description {
    color: #4c6272;
}

.app-homepage .app-section__content .nhsuk-action-link {
    padding-top: 8px;
}

.app-list-signage {
    list-style: none;
    padding-left: 0;
    padding-top: 8px;
}

.app-list-signage li {
    display: inline-block;
    margin: 2px 0;
    width: 100%;
}

.app-list-signage li:last-of-type {
    margin-bottom: 2px;
}

.app-list-signage li a {
    background: #005eb8;
    color: #fff;
    display: block;
    padding: 12px 24px;
    position: relative;
    text-decoration: none;
    width: 100%;
}

@media (max-width: 40.0525em) {
    .app-list-signage li a {
        padding: 12px 16px;
    }
}

.app-list-signage li a:hover {
    background: #003d78;
    box-shadow: none;
    text-decoration: underline;
}

.app-list-signage li a:active {
    background: #002f5c;
}

.app-list-signage li a:focus {
    background: @c-yellow;
    box-shadow: inset 0 -4px 0 0 #212b32;
    color: #212b32;
}

.app-list-signage li a:focus .nhsuk-icon {
    fill: #212b32;
}

.app-list-signage li a:focus:hover {
    text-decoration: none;
}

.app-list-signage li a .nhsuk-icon {
    fill: #fff;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 14px;
    width: 24px;
}

@media (max-width: 40.0525em) {
    .app-list-signage li a .nhsuk-icon {
        top: 12px;
    }
}

@media (min-width: 48.0625em) {
    .app-list-signage--two-columns {
        column-count: 2;
        column-gap: 32px;
    }
}

.app-list-apps {
    padding: 0;
}

.app-list-apps__item {
    display: inline-block;
    list-style: none;
    margin: 0;
    width: 100%;
}

@media (max-width: 48.0525em) {
    .app-list-apps__item {
        border-bottom: 1px solid #d8dde0;
    }

    .app-list-apps__item:last-child {
        border: 0;
        margin-bottom: 0;
    }
}

.app-list-apps__link {
    display: block;
    min-height: 100px;
    padding: 16px 0 16px 92px;
    position: relative;
    text-decoration: none;
}

.app-list-apps__link:active,
.app-list-apps__link:focus {
    background: 0 0;
    box-shadow: none;
    outline: 0;
}

.app-list-apps__link:active .app-list-apps__title,
.app-list-apps__link:focus .app-list-apps__title {
    background: @c-yellow;
    box-shadow: inset 0 -4px 0 0 #212b32;
    color: #212b32;
}

@media (max-width: 48.0525em) {
    .app-list-apps__link {
        min-height: 92px;
        padding-left: 80px;
    }
}

@media (max-width: 40.0525em) {
    .app-list-apps__link {
        min-height: 88px;
        padding-left: 68px;
    }
}

.app-list-apps__icon {
    border: 1px solid #f0f4f5;
    border-radius: 12px;
    height: 72px;
    left: 0;
    position: absolute;
    top: 16px;
    width: 72px;
}

@media (max-width: 48.0525em) {
    .app-list-apps__icon {
        height: 64px;
        width: 64px;
    }
}

@media (max-width: 40.0525em) {
    .app-list-apps__icon {
        height: 56px;
        width: 56px;
    }
}

.app-list-apps__title {
    font-weight: 600;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.55556;
    text-decoration: underline;
}

@media (min-width: 40.0625em) {
    .app-list-apps__title {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 1.45455;
    }
}

@media print {
    .app-list-apps__title {
        font-size: 18pt;
        line-height: 1.15;
    }
}

.app-list-apps__category {
    font-weight: 400;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.71429;
    color: #4c6272;
    display: block;
    margin-top: 8px;
}

@media (min-width: 40.0625em) {
    .app-list-apps__category {
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.5;
    }
}

@media print {
    .app-list-apps__category {
        font-size: 14pt;
        line-height: 1.2;
    }
}

@media (max-width: 48.0525em) {
    .app-list-apps__category {
        margin-top: 4px;
    }
}

@media (min-width: 48.0625em) {
    .app-list-apps--two-columns {
        column-count: 2;
        column-gap: 32px;
    }
}

img {
    max-width: 100%;
}

.app-download__link {
    margin-bottom: 16px;
    display: inline-block;
}

@media (min-width: 40.0625em) {
    .app-download__link {
        margin-bottom: 24px;
    }
}

.app-download__thumbnail {
    display: block;
}

.app-top-tasks .nhsuk-card-group {
    display: block;
    flex-wrap: nowrap;
}

.app-top-tasks .block-external_link,
.app-top-tasks .block-page_link {
    box-sizing: border-box;
    display: flex;
    padding: 0 16px;
}

@media (max-width: 48.0525em) {
    .app-top-tasks .block-external_link,
    .app-top-tasks .block-page_link {
        flex: 0 0 100%;
        margin-bottom: 32px;
    }
}

@media (min-width: 48.0625em) {
    .app-top-tasks .block-external_link,
    .app-top-tasks .block-page_link {
        float: left;
        width: 33.3333%;
    }
}

.app-top-tasks .nhsuk-card-group__item {
    display: block;
}

.app-top-tasks .nhsuk-grid-column-one-third {
    float: none;
    padding: 0;
    width: 100%;
}

.app-top-tasks .nhsuk-card {
    height: 100%;
}

.app-apps-store-link {
    margin: 8px;
    margin-left: 0;
    display: inline-block;
    vertical-align: top;
    width: 200px;
}

@media (min-width: 40.0625em) {
    .app-apps-store-link {
        margin: 16px;
    }
}

@media (min-width: 40.0625em) {
    .app-apps-store-link {
        margin-left: 0;
    }
}

.app-apps-store-link a:active,
.app-apps-store-link a:focus,
.app-apps-store-link a:hover {
    background-color: transparent;
    box-shadow: none;
}

.app-apps-store-link img {
    width: 100%;
}

.app-table-image {
    display: block;
    margin-top: 5px;
}

@media (min-width: 48.0625em) {
    .nhsuk-header__navigation {
        max-width: 960px;
        max-width: 100%;
    }
}

.beta-hub-main-topic .beta-card__content {
    padding-right: 75px;
}

.beta-hub-main-topic .beta-hub-arrow {
    display: block;
    margin-top: -8px;
    position: absolute;
    right: 24px;
    top: 50%;
}

.beta-card--plain {
    background: 0 0;
    border-bottom: 4px solid #d8dde0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

.beta-card--plain .beta-card__content {
    padding: 0 0 32px;
}

.nhsuk-card div.nhsuk-card__description > :last-child {
    margin-bottom: 0;
}

.beta-hub-key-links {
    list-style-type: none;
    margin: 0;
    padding: 1.125em 0;
}

.beta-hub-related-links {
    padding-top: 8px;
    list-style-type: none;
    padding-left: 0;
}

@media (min-width: 40.0625em) {
    .beta-hub-related-links {
        padding-top: 16px;
    }
}

.beta-hub-related-links-title > * {
    margin-bottom: 0;
}

.beta-hub-related-links__list-item {
    padding-bottom: 8px;
}

@media (min-width: 40.0625em) {
    .beta-hub-related-links__list-item {
        padding-bottom: 16px;
    }
}

.beta-hub-border-top {
    border-top: 4px solid rgba(204, 204, 204, 0.5);
    padding-top: 40px;
}

@media (min-width: 40.0625em) {
    .beta-hub-border-top {
        padding-top: 48px;
    }
}

.beta-hub-sibling-nav {
    border-top: 0;
}

.beta-hub-sibling-nav .beta-hub-sibling-nav--current {
    border-left: 4px solid #212b32;
    color: #212b32;
    margin-left: -12px;
    padding-left: 8px;
}

.beta-hub-sibling-nav .beta-hub-sibling-nav__item {
    padding-bottom: 4px;
    padding-top: 4px;
}

.beta-hub-sibling-nav .beta-hub-sibling-nav__list {
    padding-left: 12px;
}

.beta-breadcrumb {
    background-color: transparent;
    padding-bottom: 16px;
    padding-top: 20px;
}

.beta-icon__chevron-right-circle {
    fill: #005eb8;
}

.beta-hub-after-key-links > h2,
.beta-hub-bottom-content > h2,
.beta-hub-key-links__list-item,
.nhsuk-heading-s,
h4 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.55556;
    display: block;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 16px;
}

@media (min-width: 40.0625em) {
    .beta-hub-after-key-links > h2,
    .beta-hub-bottom-content > h2,
    .beta-hub-key-links__list-item,
    .nhsuk-heading-s,
    h4 {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 1.45455;
    }
}

@media print {
    .beta-hub-after-key-links > h2,
    .beta-hub-bottom-content > h2,
    .beta-hub-key-links__list-item,
    .nhsuk-heading-s,
    h4 {
        font-size: 18pt;
        line-height: 1.15;
    }
}

@media (min-width: 40.0625em) {
    .beta-hub-after-key-links > h2,
    .beta-hub-bottom-content > h2,
    .beta-hub-key-links__list-item,
    .nhsuk-heading-s,
    h4 {
        margin-bottom: 24px;
    }
}

.beta-hub-bottom-content > ul + h2 {
    padding-top: 4px;
}

@media (min-width: 40.0625em) {
    .beta-hub-bottom-content > ul + h2 {
        padding-top: 8px;
    }
}

main a[href=""]:visited,
main a[href=""]:visited .nhsuk-action-link__text {
    color: #005eb8;
}

main a[href=""]:hover,
main a[href=""]:hover .nhsuk-action-link__text {
    color: #7C2855;
}

main a[href=""]:active,
main a[href=""]:active .nhsuk-action-link__text {
    color: #002f5c;
}

main a[href=""]:focus,
main a[href=""]:focus .nhsuk-action-link__text,
main .app-list-signage a[href=""]:focus {
    color: #212b32;
}

main .app-list-signage a[href=""] {
    color: #ffffff;
}

.nhsuk-header__content {
    display: flex;
    align-items: flex-end;

    @media @mobile {
        display: block;
    }

    .top-nav__accessibility {
        color: #fff;
        background: none;
        border: none;
        font-size: 1rem;
        margin-bottom: 10px;
        display: inline-block;
        text-decoration: underline;
        padding: 0;

        @media @mobile {
            display: block;
            margin-left: auto;
            margin-bottom: 27px;
        }

        &--after {
            &:after {
                content: '|';
                margin: 0 5px 0 10px;
            }
        }

        &:hover {
            cursor: pointer;
        }
        /*
        &--loading {
            &:after {
                content: '\f110';
                animation: spinner linear 1s;
                animation-iteration-count: infinite;
            }
        }*/
    }
}

.nhsuk-header__lang-switcher {
    display: inline-block;
}