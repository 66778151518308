.button {
    &--rte {
        a {
            color: inherit;
            text-decoration: none;
        }
    }

    &--small {
        font-size: 14px;
        padding: 5px 10px 0;
        margin: 0 0 2px 0;
    }

    
    &--form {
        height: 2.25rem;
        padding-top: 0.1875rem;
        padding-bottom: 3px;
        margin: 0 0 0 0.625rem;
    }

    &--back {
        position: relative;
        padding-left: 30px;
    }

    &__icon {
        position: absolute;
        left: 0;
        top: -7px;
        width: 25px;
    }
}