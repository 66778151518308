@font-face {
	font-family: "factoryIcons";
	src: url('/fonts/factoryIcons/factoryIcons.woff2') format('woff2');
	font-display: swap;
}

.icon {
	display: inline-flex;
}

.icon:before {
	font-family: "factoryIcons";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-decoration: none;
	text-transform: none;
	line-height: 1;
}


.icon--accessibility:before {
	content: "\E001";
}

.icon--arrow-right:before {
	content: "\E002";
}

.icon--chevron:before {
	content: "\E003";
}

.icon--close:before {
	content: "\E004";
}

.icon--Graph_2:before {
	content: "\E005";
}

.icon--info:before {
	content: "\E006";
}