.pagination {
    margin: 30px 0;
    padding: 0;
    list-style: none;
    border-top: 2px solid #eee;
    padding-top: 30px;
    margin-bottom: 80px;
    display: flex;
    flex-flow: row wrap;
    gap: ~"calc(var(--baseline) / 3)";

    &__item {
        display: inline-block;
        background-color: transparent;
        color: var(--brand-primary--shade);
        font-weight: 600;
        border-bottom: 2px solid;
        border-color: transparent;
        // &:hover:not(&--active) {
        //     background-color: #fff;
        // }

        &--active {
            background-color: #fff;
            color: var(--brand-primary--shade);
            border-color: var(--brand-primary--shade);
        }

        &.disabled {
            display: none;
        }
    }

    &__link {
        color: inherit;
        text-decoration: none;
        padding: 10px;
        font-size: 1.125rem;
        display: block;
        min-width: 36px;
        text-align: center;

        &:hover {
            background-color: transparent;
        }

        &:hover:not(&--active) {
            background-color: #fff;
        }

    }
}