.near-me {
    &__button {
        display: flex;
        align-items: center;
        background-color: #f0f4f5;
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 600;
        color: @c-body;
        font-size: 15px;
        text-decoration: none;

        &:before {
            content: '';
            background-image: url(/img/nearme.png);
            background-size: 100% 100%;
            display: block;
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
    }

    &__container {
        position: relative;
        
        @media @tablet-min {
            position: absolute;
            top: 30px;
            right: 30px;
        }
    }

    &__img {
        @media @tablet {
            width: 50px;
            height: 50px;
            margin: 0 0 30px;
        }
    }
}