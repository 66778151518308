.header {
    background-color: var(--brand-primary--shade);
}

.accessibilityContainer {
    display: flex;
    justify-content: flex-end;

    .icon--accessibility {
        background-color: #fff;
        border-radius: 50%;
        color: #4C3387;
        padding: .25rem;
        font-size: .75em;
        pointer-events: none;
    }
}

.box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 36px;
    margin-left: 5px;
    padding: 10px;
    background-color: #f9f9f9;
    color: white;
}

.text {
    margin-right: 10px;
    color: white;
}


.bgColor1 {
    background-color: #4C3387;
}

.bgColor2 {
    background-color: var(--brand-secondary);
}

.navigation__list {
    margin-top: 0;
}

.on--off-canvas-nav .off-canvas-nav__container,
.on--off-canvas-search .off-canvas-search__container {
    background-color: var(--brand-primary--shade);
}