.nhsuk-width-container {
    .container;
}

.nhsuk-grid-column-full {
    padding: 0;
}
//.nhsuk-card-group__item {
.nhsuk-card--clickable {
    transition: transform .2s;

    &:hover {
        transform: scale(1.03);
    }
}
//}
.nhsuk-header {
    background-color: @c-blue;
    // position: sticky;
    // top: 0;
    z-index: 100;
    // height: @header-height;
    // @media @tablet {
    //     height: @header-height--mobile;
    // }
    // &__container {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     &:after {
    //         display: none;
    //     }
    // }

    &__menu {
        @media @tablet {
            margin-right: 3.125rem;
        }
        /*  @media @mobile {
            margin-right: 0;
        }*/
    }

    &__link {
        height: 57px;
        margin-left: -20px;
        padding-left: 20px;
        width: 150px;
        padding-right: 20px;

        img {
            background: #124987;
        }
    }

    &__navigation {
        overflow: visible;
        z-index: 10;
        position: sticky;
        top: 0;
        width: 100%;

        @media @tablet {
            position: relative;
        }

        &-list {
            position: relative;
            border: 0;
        }

        &-item {
            position: static;

            &--children {
                .nav__item-container {
                    @media @tablet {
                        display: flex;
                        justify-content: space-between;
                    }
                }

                &.active {
                    .nav__dropdown {
                        .nav__dropdown-list {
                            @media @tablet {
                                column-count: unset;
                                padding-top: 0;
                            }
                        }

                        .nav__dropdown-intro {
                            @media @tablet {
                                display: none;
                            }
                        }

                        @media @tablet {
                            display: block;
                            position: relative;
                            box-shadow: none;
                        }
                    }
                }

                a {
                    @media @tablet {
                        position: relative;
                    }
                }
            }

            &--current {
                box-shadow: inset 0 -4px 0 #d8dde0;
            }
        }
    }

    &__lang-switcher {
        p {
            .font(@font-size: 16, @line-height: 19, @letter-spacing: .5);
            color: #fff;
            margin-bottom: .8rem;
        }

        a:visited {
            color: #fff;
        }
    }
    // &__content {
    //     display: flex;
    // }
}

.nhsuk-footer {
    border-top: 0;
}

// .nhsuk-breadcrumb {
//     margin-bottom: 1.875rem;
// }
@media (min-width: 40.0625em) {
    .nhsuk-hero--image-description .nhsuk-hero-content {
        bottom: 35px;
    }
}

.nhsuk-hero-content {
    z-index: 2;
    //  bottom: 35px;

    &:not(.body--homepage &) {
        bottom: 35px;
    }
}

.nhsuk-section:not(.section--cta) {
    .app-homepage & {
        &:nth-of-type(odd) {
            padding-top: @content-padding;
            padding-bottom: @content-padding;
            margin: 0;
            background-color: #FFF;
        }

        &:first-of-type {
            padding-top: 5rem !important;
        }
    }
}

.nhsuk-list {
    &__item {
        padding: 10px 0;

        &:not(:last-of-type) {
            border-bottom: 2px solid #eee;
            ;
        }
    }
}

ul, .nhsuk-list--bullet {
    margin-top: 8px;
}

.nhsuk-card {
    &--full {
        height: 100%;
    }
}

.app-list-signage--two-columns {
    display: flex;
    flex-wrap: wrap;
}

.app-list-signage li a {
    display: flex;
    height: 100%;
}

.app-list-signage li {
    flex: 0 0 ~"calc(50% - 16px)";
}

.nhsuk-warning-callout {
    margin: 0;

    a {
        color: inherit;
    }
}

.app-section__content {
    padding: 0;
}
// .nhsuk-grid-row {
//     margin: 0;
// }

.nhsuk-skip-link:active, .nhsuk-skip-link:focus {
    z-index: 101;
}

.nhsuk-hero__overlay {
    .nhsuk-hero--image & {
        min-height: 400px;
    }

    a {
        color: inherit;
    }
}

.nhsuk-header__content {
    @media only screen and (max-width: 640px) and (min-width: 481px) {
        justify-content: flex-end;
        align-items: flex-start;
    }
}

.nhsuk-header__menu {
    @media only screen and (max-width: 640px) and (min-width: 481px) {
        margin-right: 0;
    }
}

.nhsuk-header__search-toggle {
    @media only screen and (max-width: 640px) and (min-width: 481px) {
        position: relative;
        margin-right: .5rem;
    }
}

@media (max-width: 40.0525em) {
    .nhsuk-breadcrumb__list {
        display: block;
    }
}

.ccc-link {
    &:focus {
        .ccc-statement-name {
            color: #212b32;
        }
    }
}

#ccc .ccc-content--dark a, #ccc .ccc-content--dark h1, #ccc .ccc-content--dark h2, #ccc .ccc-content--dark h3, #ccc .ccc-content--dark p, #ccc .ccc-content--dark span {
    &:focus {
        color: #212b32;

        .ccc-statement-name {
            color: #212b32;
        }
    }
}