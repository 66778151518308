.row {
    width: auto;
    margin: 0 -@column-padding;
    display: flex;
    flex-wrap: wrap;
    // &:after {
    //     content: '';
    //     display: table;
    //     clear: both;
    // }
    &--reverse {
        flex-direction: row-reverse;
    }
}


.container {
    width: 100%;
    max-width: @container-width;
    margin: 0 auto;
    // position: relative;
    // padding: 0 @layout-padding;
    padding-left: @layout-padding;
    padding-right: @layout-padding;

    .block-list & {
        position: relative;
        z-index: 2;
    }

    .section--half & {
        padding-left: @layout-padding/2;
        padding-right: @layout-padding/2;
    }
    // &--static {
    //     position: static;
    // }
    // &--center {
    //     max-width: @container-width--small;
    // }
    // &--max-width {
    //     max-width: 800px;
    // }
    // &--fluid {
    //     max-width: none;
    // }
    // &--half {
    //     max-width: @container-width / 2;
    //     margin: 0;
    // }
}

// *,
// *:after,
// *:before {
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
// }

@cols: 12;

.generateCols(@counter) when (@counter > 0) {
    @width: round((@counter / @cols ) * 100, 4);

    .col-@{counter} {
        flex-basis: ~"@{width}%";
        max-width: ~"@{width}%";
    }

    .generateCols((@counter - 1));
}

.generateCols(@cols);

// // Desktop
// .generateColsDesktop(@counter) when (@counter > 0) {
//     @width: round((@counter / @cols ) * 100, 4);

//     .desktop-@{counter} {
//         flex-basis:~"@{width}%";
//         max-width:~"@{width}%";
//     }

//     .generateColsDesktop((@counter - 1));
// }

// @media @desktop {
//     .generateColsDesktop(@cols);
// }

// Tablet
.generateColsTablet(@counter) when (@counter > 0) {
    @width: round((@counter / @cols ) * 100, 4);

    .tablet-@{counter} {
        flex-basis: ~"@{width}%";
        max-width: ~"@{width}%";
    }

    .generateColsTablet((@counter - 1));
}

@media @tablet {
    .generateColsTablet(@cols);
}

// Mobile
// .generateColsMobile(@counter) when (@counter > 0) {
//     @width: round((@counter / @cols ) * 100, 4);

//     .mobile-@{counter} {
//         flex-basis:~"@{width}%";
//         max-width:~"@{width}%";
//     }

//     .generateColsMobile((@counter - 1));
// }

// @media @mobile {
//     .generateColsMobile(@cols);
// }

// Push generate
// .generatePush(@counter) when (@counter > 0) {
//     @width: round((@counter / @cols ) * 100, 4);

//     .push-@{counter} {
//         left:~"(100 / @{cols}) * @{width}%";

//         @media @tablet {
//             left: 0;
//         }
//     }

//     .generatePush((@counter - 1));
// }

// .generatePush(@cols);

// Pull generate
// .generatePull(@counter) when (@counter > 0) {
//     @width: round((@counter / @cols ) * 100, 4);

//     .push-@{counter} {
//         left:~"(100 / @{cols}) * @{width}%";

//         @media @tablet {
//             left: 0;
//         }
//     }

//     .generatePull((@counter - 1));
// }

// .generatePull(@cols);

// Target all cols
[class*='col-'] {
    padding: 0 @column-padding;
    position: relative;
    min-height: 1px;

    @media @tablet {
        &:not(:last-of-type) {
            margin-bottom: 1.875rem;
        }
    }

    &:not([class*='tablet-']) {
        @media @tablet {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

.col {
    &--grow {
        max-width: none;
        flex-grow: 1;
    }

    &--border {
        border-left: 2px solid #ccc;
        padding-left: @column-padding*2;
    }
}