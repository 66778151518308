.label {
    &--large {
        font-size: 1.5rem;
    }
}

.validation-summary-errors {
    color: @c-red;
    font-weight: 700;
}

.field-validation-error {
    color: @c-red;
    font-weight: 700;
    margin-top: 5px;
    display: block;
}

.checkbox-container label {
    cursor: pointer;
}

.custom-radio,
.custom-checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    color: var(--brand-primary--shade);

    --checkbox-size: 1.25em;
    --checked-state-bg-color: var(--brand-primary);
    --checked-state-check-color: #fff;
    --outline-color: var(--highlight);
    --outline-offset: 3px;

    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        width: var(--checkbox-size);
        height: var(--checkbox-size);
        opacity: 0.00001;
        cursor: pointer;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;

        &:checked+svg {

            .radio__background,
            .checkbox__background {
                fill: var(--checked-state-bg-color);
                stroke: var(--checked-state-bg-color);
            }

            .radio__checkmark,
            .checkbox__checkmark {
                stroke: var(--checked-state-check-color);
            }
        }

        &:focus+svg {
            outline: 4px solid var(--outline-color);
            outline-offset: var(--outline-offset);
        }

        &:focus:not(:focus-visible)+svg {
            outline: none;
        }
    }

    svg {
        width: var(--checkbox-size);
        height: var(--checkbox-size);
        min-width: var(--checkbox-size);
        margin-right: 0.6em;
        margin-top: .1em;

        * {
            transition: all 0.1s linear;
        }


        color: var(--brand-primary--shade);

    }
}

.checkbox__checkmark {
    transform: scale(0.8);
    transform-origin: center;
}