.tag-list {
    display: flex;
    column-gap: .4rem;
    grid-row-gap: .4rem;
    flex-wrap: wrap;

    &--search {
        margin-top: var(--baseline);
    }

    &--related-content {
        margin-bottom: calc(var(--baseline) / 3);
    }
}

.tag {
    --tag-background-color: #fff;
    --tag-color: var(--brand-primary--shade);
    --tag-border-color: var(--light-grey-3);
    --tag-hover-background-color: var(--light-grey-1);
    background-color: var(--tag-background-color);
    color: var(--tag-color);
    border: 1px solid var(--tag-border-color);
    padding: .125rem .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    font-weight: 600;
    line-height: 1.2;

    &:hover {
        background-color: var(--tag-hover-background-color);
    }

    >.icon {
        font-size: 1em;
    }


    &--search {
        --tag-background-color: var(--light-grey-2);
        --tag-border-color: var(--light-grey-2);
        --tag-hover-background-color: #fff;
    }

    &--region {
        --tag-background-color: var(--brand-primary--shade);
        --tag-color: #fff;
        --tag-border-color: var(--brand-primary--shade);
        --tag-hover-background-color: var(--brand-primary);
    }

    &--topic {
        --tag-background-color: var(--brand-primary);
        --tag-color: #fff;
        --tag-border-color: var(--brand-primary);
        --tag-hover-background-color: var(--brand-primary--shade);
    }

    &--content-type {
        --tag-background-color: var(--brand-secondary);
        --tag-color: #fff;
        --tag-border-color: var(--brand-secondary);
        --tag-hover-background-color: var(--brand-secondary--shade);
    }


    &--markets {
        --tag-background-color: var(--brand-primary--tint);
        --tag-color: #fff;
        --tag-border-color: var(--brand-primary--tint);
        --tag-hover-background-color: var(--brand-primary--tint-dark);
    }

}