.search-box {
    margin: 3.125rem 0;

    &--bg {
        border: 1px solid #e7e7e7;
        padding: 1.875rem;
        background-color: #FFF;
        margin: 1.875rem 0;
    }

    &--nearest {
        @media @mobile {
            margin-top: 0;
        }

        .nhsuk-select {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 10px;

        &--filter {
            display: inline-block;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        border: 0;
        padding: 0;
        margin: 0 0.625rem 0.625rem 0;
    }

    &__button {
        border: 2px solid #eee;
        background-color: #FFF;
        padding: 0.625rem 1.25rem;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        font-weight: 600;
        cursor: pointer;
        .transition(.3s);

        &--small {
            padding: 0.3125rem 0.625rem;
            min-width: 2.125rem;
        }

        &:hover {
            background-color: @c-main;
            border-color: @c-main;
            color: #FFF;
        }

        &.search__aggregation-icon--active {
            background-color: @c-main;
            border-color: @c-main;
            color: #FFF;

            &:after {
                content: 'x';
                position: absolute;
                top: -4px;
                right: 5px;
            }
        }

        &:before {
            display: none;
        }
    }

    &__more {
        color: @c-main;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin-top: 1.25rem;
        cursor: pointer;
    }
}
 
#clearNewsFilters {
   // float: right;
   display: block;
   margin-bottom: 20px;
   max-width: 150px;
   display: none;
}
#noFiltersSelected {
    display: block;
    margin-bottom: 20px;
} 