.slider__navigation {
    margin-left: auto;

    &--4 {
        &.slider__navigation--alt {
            @media only screen and (min-width : 1401px) {
                display: none;
            }
        }
    }

    &--3 {
        @media only screen and (min-width : 960px) {
            display: none;
        }
    }

    &--2 {
        @media only screen and (min-width : 600px) {
            display: none;
        }
    }

    &--1 {
        display: none;
    }
}