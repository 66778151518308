img,
iframe {
    display: block;
    max-width: 100%;
}

// .highlight {
//     color: @c-secondary;
// }

// .custom-bullet-list {
//     list-style-type: none;
//     margin: 0;
//     padding: 0;
//     .fa {
//         position: absolute;
//         left: 0;
//     }  
// }

// .custom-bullet {
//     padding-left: 25px;
//     margin-bottom: 15px; 
// }

.video-container {
    position: relative;
    padding-top: 52.5%;
    width: 100%;

    &--carousel {
        padding-top: 50%;
    }

    iframe {
        .position--cover;
        object-fit: cover;
    }
}

.anchor-link {
    position: relative;
    top: -4.375rem;
    display: block;
    width: 1px;
    height: 1px;
    visibility: hidden;

    @media @tablet {
        top: -1.25rem;
    }
}

.last-updated {
    margin: 25px 0;

    p {
        margin-bottom: 0;
    }

    &__text {
        font-weight: 600;
    }
}

.ward-list__input-container {
    margin-bottom: 25px;
}

.az {
    &__item {
        border: 2px solid #eee;
        background-color: #FFF;
        padding: 0.625rem 1.25rem;
        text-decoration: none;
        font-weight: 600;
        border-radius: 5px;
        .transition(.3s);

        &:hover {
            background-color: @c-main;
            border-color: @c-main;
            color: #FFF;
        }
    }
}

.accordion__title {
    font-size: 1.1875rem;
    margin-bottom: 0;
    font-weight: 100;
}

.content-block__insight {
    padding-bottom: calc(var(--baseline)* 1.5);

    @media @tablet-min {
        padding-bottom: calc(var(--baseline)* 2);
    }
}