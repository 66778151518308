.hero {
    position: relative;
    background-color: var(--brand-primary--shade);
}

.hero__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.hero__content {
    z-index: 100;
    position: relative;
}

.hero__title {
    max-width: 22ch;
}

.hero-form {
    margin-top: calc(var(--baseline)* 2.5);
}

.hero--home .wrap:before {
    content: none;
}

.autocomplete__hint,
.autocomplete__input {
    background-color: rgba(255, 255, 255, 0.25);

    &:focus {
        background-color: rgba(255, 255, 255, 0.25);
    }
}

.hero-form__label {
    margin-bottom: calc(var(--baseline) / 1.5);
}

.off-canvas-search__input {

    .autocomplete__input,
    .autocomplete__hint {
        font-size: var(--font-size-plus-3);
        background-color: transparent;
        padding: 0;

        @media @tablet-min {
            font-size: var(--font-size-plus-5);
        }
    }


}

.off-canvas-search__submit {
    position: static;
}

.hero-form__wrapper--off-canvas {
    display: flex;
}

.hero-form {
    .autocomplete__input {
        &:focus {
            background-color: #fff;
            color: var(--brand-primary--shade);
            font-weight: 600;

            &::placeholder {
                color: var(--dark-grey-2);
            }
        }
    }

    .autocomplete__hint {
        color: var(--brand-primary--shade);
        font-weight: 600;
    }
}


.hero-form__wrapper:has(.autocomplete__input:focus) .hero-form__icon {
    fill: var(--brand-primary--shade);
}

.page-header {
    position: relative;

    &--light {
        padding-top: 0;

        .breadcrumb {
            padding-bottom: calc(var(--baseline)* 2.5);
        }
    }

    &--hero {
        padding-bottom: calc(var(--baseline) * 3);
        padding-top: 0;

        @media @tablet-min {
            padding-bottom: calc(var(--baseline) * 5);
        }

        .page-header__title,
        .page-header__summary,
        .page-header__label,
        .page-header__date {
            color: #fff;
        }

        .breadcrumb {
            padding-bottom: calc(var(--baseline) * 2.5);
            background-color: transparent;
            z-index: 1;
            position: relative;

            .breadcrumb__link {
                color: #fff;
            }

            .breadcrumb__item:before {
                border-right: 1px solid #fff;
                border-top: 1px solid #fff;
            }

            .breadcrumb__inner {
                border-bottom: 0;
            }

            &.breadcrumb--dark .breadcrumb__inner:before {
                content: none;
            }
        }
    }
}