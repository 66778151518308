.local-service__listing {
    .nhsuk-expander {
        margin-bottom: 12px;
    }

    .nhsuk-details__summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.local-service__main-content {
    margin-top: 1rem;
}

.local-service__back-link {
    margin-top: 20px;
}

.info-box {
    &__item {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__label {
        display: block;
        font-weight: 700;
    }
    // &__near-me {
    //     position: absolute;
    //     right: 20px;
    //     top: 20px;
    // }
    // &__near-me-img {
    //     height: 70px;
    //     width: 70px;
    // }
}

.info-box__near-me {
    position: relative;
}

#wardListMacro {
    position: relative;
}

.address-line {
    display: block;
}

.local-service__text {
    position: relative;
}

.local-service__address-text {
    font-weight: 600;
    //    margin-bottom: 0;
}

.local-service__link {
    position: absolute;
    bottom: 0;
    right: 0;
}

p.summary {
    margin-top: 15px;
    margin-bottom: 0;
}

#serviceResults {
    .local-service__contact-details {
        margin-bottom: 4rem;
    }
}

.additional-information {
    margin-top: 20px;
}