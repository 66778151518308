.hero-chart {
    margin-top: calc(var(--baseline)* 2);
    border-top: 0;

    &__embed {
        border-top: 0;
    }
}

.data__details {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    padding-top: calc(var(--baseline));
    margin-bottom: calc(var(--baseline)* 1.5);

    @media @tablet-min {
        padding-top: calc(var(--baseline)* 1.5);
        padding-bottom: var(--baseline);
        margin-bottom: calc(var(--baseline)* 2);
    }
}

.card--text .card__title {
    font-weight: var(--heavy-weight);
}

.card__date {
    color: var(--brand-primary--shade);
    font-size: 1.125em;
}

.editor--last-updated {
    border-top: 1px solid var(--light-grey-3);
    padding-top: calc(var(--baseline) / 2);
    margin-top: calc(var(--baseline) * 1.5);

    .backgroundPink & {
        border-top: 1px solid #eaeaea;
    }
}